<template>
    <div>
        <div class="form-step">
            <div v-if="currentStep === 1">
                <h3>STEP 1 : INFORMASI SALESMAN</h3>
                <Button :class="currentStep === 1 ? 'p-button-text' : 'p-button-text text-900'"
                    label="STEP 1 : INFORMASI SALESMAN" />
                <Button class="p-button-text text-900" label="STEP 2: INFORMASI OUTLET" />
                <Divider />
            </div>
            <div v-if="currentStep === 2">
                <h3>STEP 2: INFORMASI OUTLET</h3>
                <Button class="p-button-text text-900" label="STEP 1 : INFORMASI SALESMAN" />
                <Button :class="currentStep === 2 ? 'p-button-text' : 'p-button-text text-900'"
                    label="STEP 2: INFORMASI OUTLET" />
                <Divider />
            </div>
        </div>
        <div class="p-fluid">
            <div v-if="currentStep === 1">
                <div class="formgrid grid">
                    <div class="field col-12 md:col-6 lg:col-3">
                        <label>Nama Beat</label>
                        <InputText v-model="forms.beat_name" required="true" autofocus
                            :class="{ 'p-invalid': errorAdd.beat_name }" />
                        <small class="p-invalid" style="color: red" v-if="errorAdd.beat_name">{{
                            errorAdd.beat_name[0]
                        }}</small>
                    </div>
                    <div class="field col-12 md:col-6 lg:col-3">
                        <label>Area</label>
                        <Dropdown id="provinsi_id" dataKey="provinsi_id" v-model="forms.provinsi_id" :loading="loadingDropdownProvinsi"
                            :options="dataDropdownProvinsi" :class="{ 'p-invalid': errorAdd.provinsi_id }"
                            optionLabel="provinsi_name" optionValue="provinsi_id" placeholder="Pilih Area"
                            :filter="true" :showClear="true" @filter="searchDropdownProvinsi($event, 'add')"
                            @change="changeKabupaten()" />
                        <small class="p-invalid" style="color: red" v-if="errorAdd.provinsi_id">{{
                            errorAdd.provinsi_id[0]
                        }}</small>
                    </div>
                </div>
                <div class="formgrid grid">
                    <div class="field col-12 md:col-6 lg:col-3">
                        <label>Kota</label>
                        <Dropdown id="kabupaten_id" dataKey="kabupaten_id" v-model="forms.kabupaten_id" :loading="loadingDropdownKabupaten"
                            :options="dataDropdownKabupaten" :class="{ 'p-invalid': errorAdd.kabupaten_id }"
                            optionLabel="kabupaten_name" optionValue="kabupaten_id" placeholder="Pilih Kabupaten"
                            :filter="true" :showClear="true" @filter="searchDropdownKabupaten($event, 'add')"
                            @change="changeSubDist()" />
                        <small class="p-invalid" style="color: red" v-if="errorAdd.kabupaten_id">{{
                            errorAdd.kabupaten_id[0]
                        }}</small>
                    </div>
                    <div class="field col-12 md:col-6 lg:col-3">
                        <label>Sub Dist</label>
                        <Dropdown id="subdist_id" dataKey="subdist_id" v-model="forms.subdist_id" :loading="loadingDropdownSubdist"
                            :options="dataDropdownSubdist" :class="{ 'p-invalid': errorAdd.subdist_id }"
                            optionLabel="label" optionValue="subdist_id" placeholder="Pilih Sub Dist" :filter="true"
                            :showClear="true" @filter="searchDropdownSubDist($event, 'add')"
                            @change="changeKecamatan()" />
                        <small class="p-invalid" style="color: red" v-if="errorAdd.subdist_id">{{
                            errorAdd.subdist_id[0]
                        }}</small>
                    </div>
                    <div class="field col-12 md:col-6 lg:col-3">
                        <label>Salesman</label>
                        <Dropdown id="salesman_id" dataKey="salesman_id" v-model="forms.salesman_id" :loading="loadingDropdownSalesman"
                            :options="dataDropdownSalesman" :class="{ 'p-invalid': errorAdd.salesman_id }"
                            optionLabel="label" optionValue="salesman_id" placeholder="Pilih Salesman" :filter="true"
                            :showClear="true" @filter="searchDropdownSalesman($event, 'add')" />
                        <small class="p-invalid" style="color: red" v-if="errorAdd.salesman_id">{{
                            errorAdd.salesman_id[0]
                        }}</small>
                    </div>
                </div>
                <div class="formgrid grid">
                    <div class="field col-12 md:col-6 lg:col-3">
                        <label>Survey Outlet Baru/Lama</label>
                        <div class="field-checkbox mt-2">
                            <Checkbox id="new_survey_outlet" v-model="forms.is_new_survey" :binary="true" @change="showMarker()"/>
                            <label for="new_survey_outlet">Centang jika pilih outlet survey baru</label>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="currentStep === 2">
                <div class="formgrid grid">
                    <div class="field col-12 md:col-6 lg:col-3">
                        <label>Kecamatan</label>
                        <Dropdown id="kecamatan_id" dataKey="kecamatan_id" v-model="forms.kecamatan_id" :loading="loadingDropdownKecamatan"
                            :options="dataDropdownKecamatan" :class="{ 'p-invalid': errorAdd.kecamatan_id }"
                            optionLabel="kecamatan_name" optionValue="kecamatan_id" placeholder="Pilih Kecamatan"
                            :filter="true" :showClear="true" @filter="searchDropdownKecamatan($event, 'add')"
                            @change="changeKelurahan()" />
                        <small class="p-invalid" style="color: red" v-if="errorAdd.kecamatan_id">{{
                            errorAdd.kecamatan_id[0]
                        }}</small>
                    </div>
                    <div class="field col-12 md:col-6 lg:col-3">
                        <label>Kelurahan</label>
                        <Dropdown id="kelurahan_id" dataKey="kelurahan_id" v-model="forms.kelurahan_id" :loading="loadingDropdownKelurahan"
                            :options="dataDropdownKelurahan" :class="{ 'p-invalid': errorAdd.kelurahan_id }"
                            optionLabel="kelurahan_name" optionValue="kelurahan_id" placeholder="Pilih Kelurahan"
                            :filter="true" :showClear="true" @filter="searchDropdownKelurahan($event, 'add')"
                            @change="changeSpesificKelurahan()" />
                        <small class="p-invalid" style="color: red" v-if="errorAdd.kelurahan_id">{{
                            errorAdd.kelurahan_id[0]
                        }}</small>
                    </div>
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col-12 md:col-8 lg:col-8">
                    <div class="map" id="outlet_map" :hidden="hiddenMap"></div>
                </div>
                <div class="field col-12 md:col-4 lg:col-4" v-if="currentStep === 2">
                    <div class="card" style="height: calc(68vh - 143px)">
                        <DataTable :value="forms.outlet_ids" :scrollable="true" scrollHeight="flex">
                            <Column field="name" header="Nama Outlet">
                                <template #body="slotProps">
                                    {{ slotProps.data.name }}
                                </template>
                            </Column>
                            <Column header="Aksi" headerStyle="min-width:10rem;">
                                <template #body="slotProps">
                                    <Button icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2"
                                        @click="removeOutlet(slotProps.data)" />
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-2">
            <Button label="Next" class="p-button-warning" @click="page1Next" v-if="currentStep === 1" />

            <Button label="Back" class="p-button-warning mr-2" @click="prevStep" v-if="currentStep === 2" />
            <Button label="Reset" icon="pi pi-refresh" class="p-button-danger mr-2" @click="clearForms"
                v-if="currentStep === 2" />
            <Button :loading="loading" label="Simpan" icon="pi pi-save" class="p-button-primary" @click="saveNew"
                v-if="currentStep === 2" />
        </div>
    </div>
</template>


<script>
import moment from 'moment';
import { mapGetters } from "vuex";

// eslint-disable-next-line no-undef
var markers = L.markerClusterGroup();

export default {
    data() {
        return {
            // loading
            loading: false,
            loadingMaps: false,
            loadingDropdownProvinsi: false,
            loadingDropdownKabupaten: false,
            loadingDropdownSubdist: false,
            loadingDropdownKecamatan: false,
            loadingDropdownKelurahan: false,
            loadingDropdownSalesman: false,

            // dataDropdown
            dataDropdownProvinsi: null,
            dataDropdownKabupaten: null,
            dataDropdownSubdist: null,
            dataDropdownKecamatan: null,
            dataDropdownKelurahan: null,
            dataDropdownSalesman: null,

            // addNew
            forms: {
                beat_name: null,
                salesman_id: null,
                outlet_ids: [],
                provinsi_id: null,
                kabupaten_id: null,
                subdist_id: null,
                kecamatan_id: null,
                kelurahan_id: null,
                is_new_survey: false,
            },

            // maps
            map: null,
            zoom: 5,
            lat: -3.375126556326247,
            lng: 118.84902874210889,
            location_list: [],
            hiddenMap: true,
            marker_list: [],
            currentStep: 1,
        }
    },
    mounted() {
        this.$store.commit('setErrorAdd', {});
        this.searchDropdownProvinsi('');

        this.initMap();
        this.showLegend();
        window.getOutletFromId = this.getOutletFromId.bind(this);
        window.removeOutletFromIcon = this.removeOutletFromIcon.bind(this);

    },
    computed: {
        ...mapGetters(['errorAdd']),
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        convertMultiOutlet(data) {
            if (data) {
                return data.map((item) => {
                    return item.id;
                });
            }
        },
        convertMultiIcon(data, status){
            var iconUrl = '';
            if(data.outlet_type_id == 1 && status){
                iconUrl = "/marker/marker-type-outlet-dry-beat.png";
            }else if(data.outlet_type_id == 1 && !status){
                iconUrl = "/marker/marker-type-outlet-dry.png";
            }

            if(data.outlet_type_id == 2 && status){
                iconUrl = "/marker/marker-type-outlet-lainnya-beat.png";
            }else if(data.outlet_type_id == 2 && !status){
                iconUrl = "/marker/marker-type-outlet-lainnya.png";
            }

            return iconUrl;
        },
        // DROPDOWN
        searchDropdownProvinsi(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dprov.filterValue = valueEdit;
                }

                if (purpose == "add") {
                    this.loadingDropdownProvinsi = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/area',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "add") {
                            this.dataDropdownProvinsi = res.data.data;
                            this.loadingDropdownProvinsi = false;
                        } else if (purpose == null) {
                            this.dataDropdownProvinsi = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        changeKabupaten() {
            this.forms.kabupaten_id = null;
            this.dataDropdownKabupaten = null;
            this.searchDropdownKabupaten('');

            this.forms.subdist_id = null;
            this.dataDropdownSubdist = null;
            this.searchDropdownSubDist('');

            this.forms.salesman_id = null;
            this.dataDropdownSalesman = null;
            this.searchDropdownSalesman('');

            this.forms.kecamatan_id = null;
            this.dataDropdownKecamatan = null;
            this.searchDropdownKecamatan('');

            this.forms.kelurahan_id = null;
            this.dataDropdownKelurahan = null;
            this.searchDropdownKelurahan('');

            this.showMarker();
        },
        searchDropdownKabupaten(event, purpose, valueEdit) {
            setTimeout(() => {
                if (this.forms.provinsi_id) {

                    if (valueEdit) {
                        this.$refs.dkab.filterValue = valueEdit;
                    }

                    if (purpose == "add") {
                        this.loadingDropdownKabupaten = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/kota',
                        params: {
                            "search": valueEdit ? valueEdit : event.value,
                            "provinsi_id": this.forms.provinsi_id,
                        }
                    })
                        .then(res => {

                            if (purpose == "add") {
                                this.dataDropdownKabupaten = res.data.data;
                                this.loadingDropdownKabupaten = false;
                            } else if (purpose == null) {
                                this.dataDropdownKabupaten = res.data.data;
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            }, 250);
        },
        changeSubDist() {
            this.forms.subdist_id = null;
            this.dataDropdownSubdist = null;
            this.searchDropdownSubDist('');

            this.forms.salesman_id = null;
            this.dataDropdownSalesman = null;
            this.searchDropdownSalesman('');

            this.forms.kecamatan_id = null;
            this.dataDropdownKecamatan = null;
            this.searchDropdownKecamatan('');

            this.forms.kelurahan_id = null;
            this.dataDropdownKelurahan = null;
            this.searchDropdownKelurahan('');

            this.showMarker();
        },
        searchDropdownSubDist(event, purpose, valueEdit) {
            setTimeout(() => {
                if (this.forms.provinsi_id && this.forms.kabupaten_id) {

                    if (valueEdit) {
                        this.$refs.dsubdist.filterValue = valueEdit;
                    }

                    if (purpose == "add") {
                        this.loadingDropdownSubdist = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/subdist',
                        params: {
                            "search": valueEdit ? valueEdit : event.value,
                            "provinsi_id": this.forms.provinsi_id,
                            "kabupaten_id": this.forms.kabupaten_id,
                        }
                    })
                        .then(res => {

                            if (purpose == "add") {
                                this.dataDropdownSubdist = res.data.data;
                                this.loadingDropdownSubdist = false;
                            } else if (purpose == null) {
                                this.dataDropdownSubdist = res.data.data;
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            }, 250);
        },
        changeKecamatan() {
            this.forms.salesman_id = null;
            this.dataDropdownSalesman = null;
            this.searchDropdownSalesman('');

            this.forms.kecamatan_id = null;
            this.dataDropdownKecamatan = null;
            this.searchDropdownKecamatan('');

            this.forms.kelurahan_id = null;
            this.dataDropdownKelurahan = null;
            this.searchDropdownKelurahan('');

            this.showMarker();
        },
        searchDropdownKecamatan(event, purpose, valueEdit) {
            setTimeout(() => {
                if (this.forms.provinsi_id && this.forms.kabupaten_id && this.forms.subdist_id) {

                    if (valueEdit) {
                        this.$refs.dkecamatan.filterValue = valueEdit;
                    }

                    if (purpose == "add") {
                        this.loadingDropdownKecamatan = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/kecamatan',
                        params: {
                            "search": valueEdit ? valueEdit : event.value,
                            "provinsi_id": this.forms.provinsi_id,
                            "kabupaten_id": this.forms.kabupaten_id,
                            "subdist_id": this.forms.subdist_id,
                        }
                    })
                        .then(res => {

                            if (purpose == "add") {
                                this.dataDropdownKecamatan = res.data.data;
                                this.loadingDropdownKecamatan = false;
                            } else if (purpose == null) {
                                this.dataDropdownKecamatan = res.data.data;
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            }, 250);
        },
        changeKelurahan() {
            this.forms.kelurahan_id = null;
            this.dataDropdownKelurahan = null;
            this.searchDropdownKelurahan('');
            this.changeSpesificKecamatan();
        },
        searchDropdownKelurahan(event, purpose, valueEdit) {
            setTimeout(() => {
                if (this.forms.provinsi_id && this.forms.kabupaten_id && this.forms.subdist_id && this.forms.kecamatan_id) {

                    if (valueEdit) {
                        this.$refs.dkelurahan.filterValue = valueEdit;
                    }

                    if (purpose == "add") {
                        this.loadingDropdownKelurahan = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/kelurahan',
                        params: {
                            "search": valueEdit ? valueEdit : event.value,
                            "provinsi_id": this.forms.provinsi_id,
                            "kabupaten_id": this.forms.kabupaten_id,
                            "subdist_id": this.forms.subdist_id,
                            "kecamatan_id": this.forms.kecamatan_id,
                        }
                    })
                        .then(res => {

                            if (purpose == "add") {
                                this.dataDropdownKelurahan = res.data.data;
                                this.loadingDropdownKelurahan = false;
                            } else if (purpose == null) {
                                this.dataDropdownKelurahan = res.data.data;
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            }, 250);
        },
        changeSpesificKecamatan() {

            this.marker_list = [];

            markers.clearLayers();
            // eslint-disable-next-line no-unused-vars
            this.location_list.map(function (value, key) {

                if (value.outlet_latitude != null && value.outlet_longitude != null && (this.forms.kecamatan_id == null || value.master_kelurahan.kecamatan_id == this.forms.kecamatan_id)) {

                    var iconUrl = this.convertMultiIcon(value, false); 

                    var is_exist_outlet = false;
                    for (let index = 0; index < this.forms.outlet_ids.length; index++) {
                        const element = this.forms.outlet_ids[index];
                        if (element.id == value.outlet_id) {
                            is_exist_outlet = true;
                        }
                    }

                    var htmlcontent = "<b>Kode Outlet</b> : " + value.outlet_code + "</b><br/>";
                    htmlcontent += "<b>Nama Outlet</b> : " + value.outlet_name + "<br/>";

                    if (is_exist_outlet) {
                        iconUrl = this.convertMultiIcon(value, is_exist_outlet); 
                        htmlcontent += `<button class="btn-outlet-${value.outlet_id}" onclick='event.preventDefault();removeOutletFromIcon(${value.outlet_id});' >Hapus</button>`
                    } else {
                        htmlcontent += `<button class="btn-outlet-${value.outlet_id}" onclick='event.preventDefault();getOutletFromId(${value.outlet_id});' >Tambah</button>`
                    }

                    // eslint-disable-next-line no-undef
                    var icon = L.icon({
                        iconUrl: iconUrl,
                        // shadowUrl: 'leaf-shadow.png',
                        className: `outlet-marker-${value.outlet_id}`,
                        outlet_id: value.outlet_id,
                        outlet_code: value.outlet_code,
                        outlet_type_id: value.outlet_type_id,
                        outlet_name: value.outlet_name,
                        iconSize: [21, 36], // size of the icon
                        // shadowSize:   [50, 64], // size of the shadow
                        iconAnchor: [22, 40], // point of the icon which will correspond to marker's location
                        shadowAnchor: [4, 62], // the same for the shadow
                        popupAnchor: [-10, -46], // point from which the popup should open relative to the iconAnchor
                    })


                    // eslint-disable-next-line no-undef
                    var marker = L.marker([value.outlet_latitude, value.outlet_longitude],
                        {
                            icon: icon,
                        });

                    // eslint-disable-next-line no-unused-vars
                    var popup = marker.bindPopup(htmlcontent);
                    markers.addLayer(marker);


                }
            }, (this));

            this.map.addLayer(markers);

            var markers_result = markers.getLayers().filter(function (layer) {
                // eslint-disable-next-line no-undef
                return layer instanceof L.Marker;
            });

            this.marker_list = markers_result;


        },
        changeSpesificKelurahan() {

            //Catatan jika misal kelurahan id null maka kembali ke kecamatan
            if(this.forms.kelurahan_id == null){
                this.changeSpesificKecamatan();
                return
            }

            this.marker_list = [];

            markers.clearLayers();
            // eslint-disable-next-line no-unused-vars
            this.location_list.map(function (value, key) {

                if (value.outlet_latitude != null && value.outlet_longitude != null && (this.forms.kelurahan_id == null || value.kelurahan_id == this.forms.kelurahan_id)) {

                    var iconUrl = this.convertMultiIcon(value, false); 

                    var is_exist_outlet = false;
                    for (let index = 0; index < this.forms.outlet_ids.length; index++) {
                        const element = this.forms.outlet_ids[index];
                        if (element.id == value.outlet_id) {
                            is_exist_outlet = true;
                        }
                    }

                    var htmlcontent = "<b>Kode Outlet</b> : " + value.outlet_code + "</b><br/>";
                    htmlcontent += "<b>Nama Outlet</b> : " + value.outlet_name + "<br/>";

                    if (is_exist_outlet) {
                        iconUrl = this.convertMultiIcon(value, is_exist_outlet); 
                        htmlcontent += `<button class="btn-outlet-${value.outlet_id}" onclick='event.preventDefault();removeOutletFromIcon(${value.outlet_id});' >Hapus</button>`
                    } else {
                        htmlcontent += `<button class="btn-outlet-${value.outlet_id}" onclick='event.preventDefault();getOutletFromId(${value.outlet_id});' >Tambah</button>`
                    }

                    // eslint-disable-next-line no-undef
                    var icon = L.icon({
                        iconUrl: iconUrl,
                        // shadowUrl: 'leaf-shadow.png',
                        className: `outlet-marker-${value.outlet_id}`,
                        outlet_id: value.outlet_id,
                        outlet_type_id: value.outlet_type_id,
                        outlet_code: value.outlet_code,
                        outlet_name: value.outlet_name,
                        iconSize: [21, 36], // size of the icon
                        // shadowSize:   [50, 64], // size of the shadow
                        iconAnchor: [22, 40], // point of the icon which will correspond to marker's location
                        shadowAnchor: [4, 62], // the same for the shadow
                        popupAnchor: [-10, -46], // point from which the popup should open relative to the iconAnchor
                    })


                    // eslint-disable-next-line no-undef
                    var marker = L.marker([value.outlet_latitude, value.outlet_longitude],
                        {
                            icon: icon,
                        });

                    // eslint-disable-next-line no-unused-vars
                    var popup = marker.bindPopup(htmlcontent);
                    markers.addLayer(marker);


                }
            }, (this));

            this.map.addLayer(markers);

            var markers_result = markers.getLayers().filter(function (layer) {
                // eslint-disable-next-line no-undef
                return layer instanceof L.Marker;
            });

            this.marker_list = markers_result;


        },
        searchDropdownSalesman(event, purpose, valueEdit) {
            setTimeout(() => {
                if (this.forms.provinsi_id && this.forms.kabupaten_id && this.forms.subdist_id) {

                    if (valueEdit) {
                        this.$refs.dsalesman.filterValue = valueEdit;
                    }

                    if (purpose == "add") {
                        this.loadingDropdownSalesman = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/salesman',
                        params: {
                            "search": valueEdit ? valueEdit : event.value,
                            "provinsi_id": this.forms.provinsi_id,
                            "kabupaten_id": this.forms.kabupaten_id,
                            "subdist_id": this.forms.subdist_id,
                        }
                    })
                        .then(res => {

                            if (purpose == "add") {
                                this.dataDropdownSalesman = res.data.data;
                                this.loadingDropdownSalesman = false;
                            } else if (purpose == null) {
                                this.dataDropdownSalesman = res.data.data;
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            }, 250);
        },
        // ADDNEW
        removeOutlet(data) {
            this.forms.outlet_ids = this.forms.outlet_ids.filter(outlet => outlet.id !== data.id);

          

            for (var i = 0; i < this.marker_list.length; i++) {
                var marker = this.marker_list[i];
                if (marker.options.icon.options.outlet_id == data.id) {
                    var iconUrl = this.convertMultiIcon(marker.options.icon.options, false); 
                    // eslint-disable-next-line no-undef
                    var icon = L.icon({
                        iconUrl: iconUrl,
                        // shadowUrl: 'leaf-shadow.png',
                        className: `outlet-marker-${marker.options.icon.options.outlet_id}`,
                        outlet_id: marker.options.icon.options.outlet_id,
                        outlet_type_id: marker.options.icon.options.outlet_type_id,
                        outlet_code: marker.options.icon.options.outlet_code,
                        outlet_name: marker.options.icon.options.outlet_name,
                        iconSize: [21, 36], // size of the icon
                        // shadowSize:   [50, 64], // size of the shadow
                        iconAnchor: [22, 40], // point of the icon which will correspond to marker's location
                        shadowAnchor: [4, 62], // the same for the shadow
                        popupAnchor: [-10, -46], // point from which the popup should open relative to the iconAnchor
                    })
                    var htmlcontent = "<b>Kode Outlet</b> : " + marker.options.icon.options.outlet_code + "</b><br/>";
                    htmlcontent += "<b>Nama Outlet</b> : " + marker.options.icon.options.outlet_name + "<br/>";
                    htmlcontent += `<button class="btn-outlet-${marker.options.icon.options.outlet_id}" onclick='event.preventDefault();getOutletFromId(${marker.options.icon.options.outlet_id});' >Tambah</button>`
                    marker.getPopup().setContent(htmlcontent);
                    marker.setIcon(icon);
                    break;
                }
            }

            return this.forms.outlet_ids;
        },
        addOutlet(data) {
            return this.forms.outlet_ids.push(data);
        },
        removeOutletFromIcon(id) {
            this.forms.outlet_ids = this.forms.outlet_ids.filter(outlet => outlet.id !== id);


            for (var i = 0; i < this.marker_list.length; i++) {
                var marker = this.marker_list[i];
                if (marker.options.icon.options.outlet_id == id) {
                    var iconUrl = this.convertMultiIcon(marker.options.icon.options, false); 
                    // eslint-disable-next-line no-undef
                    var icon = L.icon({
                        iconUrl: iconUrl,
                        // shadowUrl: 'leaf-shadow.png',
                        className: `outlet-marker-${marker.options.icon.options.outlet_id}`,
                        outlet_id: marker.options.icon.options.outlet_id,
                        outlet_type_id: marker.options.icon.options.outlet_type_id,
                        outlet_code: marker.options.icon.options.outlet_code,
                        outlet_name: marker.options.icon.options.outlet_name,
                        iconSize: [21, 36], // size of the icon
                        // shadowSize:   [50, 64], // size of the shadow
                        iconAnchor: [22, 40], // point of the icon which will correspond to marker's location
                        shadowAnchor: [4, 62], // the same for the shadow
                        popupAnchor: [-10, -46], // point from which the popup should open relative to the iconAnchor
                    })

                    var htmlcontent = "<b>Kode Outlet</b> : " + marker.options.icon.options.outlet_code + "</b><br/>";
                    htmlcontent += "<b>Nama Outlet</b> : " + marker.options.icon.options.outlet_name + "<br/>";
                    htmlcontent += `<button class="btn-outlet-${marker.options.icon.options.outlet_id}" onclick='event.preventDefault();getOutletFromId(${marker.options.icon.options.outlet_id});' >Tambah</button>`
                    marker.getPopup().setContent(htmlcontent);
                    marker.setIcon(icon);
                    break;
                }
            }

            return this.forms.outlet_ids;
        },
        getOutletFromId(id) {


            var outlet_name = "";

            for (var i = 0; i < this.marker_list.length; i++) {
                var marker = this.marker_list[i];
                if (marker.options.icon.options.outlet_id == id) {
                    var iconUrl = this.convertMultiIcon(marker.options.icon.options, true); 
                    outlet_name = marker.options.icon.options.outlet_name;
                    // eslint-disable-next-line no-undef
                    var icon = L.icon({
                        iconUrl: iconUrl,
                        // shadowUrl: 'leaf-shadow.png',
                        className: `outlet-marker-${marker.options.icon.options.outlet_id}`,
                        outlet_id: marker.options.icon.options.outlet_id,
                        outlet_type_id: marker.options.icon.options.outlet_type_id,
                        outlet_code: marker.options.icon.options.outlet_code,
                        outlet_name: marker.options.icon.options.outlet_name,
                        iconSize: [21, 36], // size of the icon
                        // shadowSize:   [50, 64], // size of the shadow
                        iconAnchor: [22, 40], // point of the icon which will correspond to marker's location
                        shadowAnchor: [4, 62], // the same for the shadow
                        popupAnchor: [-10, -46], // point from which the popup should open relative to the iconAnchor
                    })

                    var htmlcontent = "<b>Kode Outlet</b> : " + marker.options.icon.options.outlet_code + "</b><br/>";
                    htmlcontent += "<b>Nama Outlet</b> : " + marker.options.icon.options.outlet_name + "<br/>";
                    htmlcontent += `<button class="btn-outlet-${marker.options.icon.options.outlet_id}" onclick='event.preventDefault();removeOutletFromIcon(${marker.options.icon.options.outlet_id});' >Hapus</button>`
                    marker.getPopup().setContent(htmlcontent);
                    marker.setIcon(icon);
                    break;
                }
            }

            let data = {
                "id": id,
                "name": outlet_name,
            };

            this.addOutlet(data)
        },
        saveNew() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/master-beat/create',
                data: {
                    "beat_name": this.forms.beat_name,
                    "salesman_id": this.forms.salesman_id,
                    "outlet_ids": this.convertMultiOutlet(this.forms.outlet_ids),
                    "is_new_survey": this.forms.is_new_survey == false ? 'n' : 'y',
                },
            })
                .then((res) => {
                    this.loading = false;
                    if (res) {
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Disimpan', life: 3000, });
                        this.$store.commit('setErrorAdd', {});
                        this.clearForms();
                        this.$emit('submit');
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.loading = false;
                    this.$store.commit('setErrorAdd', err.response.data.data);
                    this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Disimpan', life: 3000, });
                });
        },
        clearForms() {
            this.forms.beat_name = null;
            this.forms.salesman_id = null;
            this.forms.outlet_ids = null;
            this.forms.provinsi_id = null;
            this.forms.kabupaten_id = null;
            this.forms.subdist_id = null;
            this.forms.kecamatan_id = null;
            this.forms.kelurahan_id = null;
            this.forms.is_new_survey = false;
            this.dataDropdownKabupaten = null;
            this.dataDropdownSubdist = null;
            this.dataDropdownSalesman = null;
            this.dataDropdownKecamatan = null;
            this.dataDropdownKelurahan = null;
            this.prevStep();
        },
        // FORMSTEP
        page1Next() {
            if (this.forms.beat_name !== null && this.forms.salesman_id !== null) {
                this.nextStep();
            } else {
                alert('Silahkan isi field terlebih dahulu');
            }
        },
        nextStep() {
            this.currentStep++;
            this.hiddenMap = false;
        },
        prevStep() {
            this.currentStep--;
            this.hiddenMap = true;
        },
        // MAPS
        initMap() {
            // eslint-disable-next-line no-undef
            this.map = L.map('outlet_map', {
                attributionControl: false,
                doubleClickZoom: true,
                zoomControl: true,
                zoom: 1,
                zoomAnimation: false,
                fadeAnimation: true,
                markerZoomAnimation: true,
                trackResize: false
            }).setView([this.lat, this.lng], this.zoom);

            // eslint-disable-next-line no-undef
            L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
                attribution: '© OpenStreetMap'
            }).addTo(this.map);
        },
        showMarker() {
            markers.clearLayers();

            this.location_list = [];

            this.forms.outlet_ids = [];

            this.marker_list = [];

            if (this.forms.provinsi_id && this.forms.kabupaten_id && this.forms.subdist_id) {
                this.loadingMaps = true;

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/master-beat/maps-outlet',
                    params: {
                        "provinsi_id": this.forms.provinsi_id,
                        "kabupaten_id": this.forms.kabupaten_id,
                        "subdist_id": this.forms.subdist_id,
                        "kelurahan_id": this.forms.kelurahan_id,
                        "is_new_survey": this.forms.is_new_survey == false ? 'n' : 'y',
                    }
                })
                    .then(res => {
                        this.location_list = res.data.data;

                        // eslint-disable-next-line no-unused-vars
                        this.location_list.map(function (value, key) {
                            if (value.outlet_latitude != null && value.outlet_longitude != null) {


                                var iconUrl = this.convertMultiIcon(value, false); //lainnya

                                // eslint-disable-next-line no-undef
                                var icon = L.icon({
                                    iconUrl: iconUrl,
                                    // shadowUrl: 'leaf-shadow.png',
                                    className: `outlet-marker-${value.outlet_id}`,
                                    outlet_id: value.outlet_id,
                                    outlet_type_id: value.outlet_type_id,
                                    outlet_code: value.outlet_code,
                                    outlet_name: value.outlet_name,
                                    iconSize: [21, 36], // size of the icon
                                    // shadowSize:   [50, 64], // size of the shadow
                                    iconAnchor: [22, 40], // point of the icon which will correspond to marker's location
                                    shadowAnchor: [4, 62], // the same for the shadow
                                    popupAnchor: [-10, -46], // point from which the popup should open relative to the iconAnchor
                                })


                                // eslint-disable-next-line no-undef
                                var marker = L.marker([value.outlet_latitude, value.outlet_longitude],
                                    {
                                        icon: icon,
                                    });

                                var htmlcontent = "<b>Kode Outlet</b> : " + value.outlet_code + "</b><br/>";
                                htmlcontent += "<b>Nama Outlet</b> : " + value.outlet_name + "<br/>";
                                htmlcontent += `<button class="btn-outlet-${value.outlet_id}" onclick='event.preventDefault();getOutletFromId(${value.outlet_id});' >Tambah</button>`

                                // eslint-disable-next-line no-unused-vars
                                var popup = marker.bindPopup(htmlcontent);
                                markers.addLayer(marker);
                            }
                        },(this));

                        this.map.addLayer(markers);

                        setTimeout(() => {
                            this.map.invalidateSize()

                        }, 5000);

                        this.loadingMaps = false;


                        var markers_result = markers.getLayers().filter(function (layer) {
                            // eslint-disable-next-line no-undef
                            return layer instanceof L.Marker;
                        });

                        this.marker_list = markers_result;


                        // console.log(markers_result);


                    })
                    .catch((err) => {
                        this.loadingMaps = false;
                        console.log(err);
                    });
            }
        },
        showLegend() {

            // eslint-disable-next-line no-undef
            var legend = L.control({ position: "bottomleft" });

            legend.onAdd = function () {

                // eslint-disable-next-line no-undef
                var div = L.DomUtil.create("div", "legend");
                div.innerHTML += "<h4>Keterangan</h4>";
                div.innerHTML += '<i class="icon" style="background-image: url(/marker/marker-type-outlet-dry.png);background-repeat: no-repeat;"></i><span>Dry</span><br>';
                div.innerHTML += '<i class="icon" style="background-image: url(/marker/marker-type-outlet-lainnya.png);background-repeat: no-repeat;"></i><span>Wet</span><br>';
                div.innerHTML += '<i style="background: red"></i><span>Sudah Punya Beat</span><br>';
                div.innerHTML += '<i style="background: black"></i><span>Belum Punya Beat</span><br>';

                return div;
            };

            legend.addTo(this.map);
        },
    }
}
</script>

<style>
.form-step {
    width: 100%;
    margin: 0 auto;
    text-align: center;
}

.map {
    /* height: 510px; */
    height: 710px;
    width: 100%;
}

/*Legend specific*/
.legend {
    padding: 6px 8px;
    font: 14px Arial, Helvetica, sans-serif;
    background: white;
    background: rgba(255, 255, 255, 0.8);
    /*box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);*/
    /*border-radius: 5px;*/
    line-height: 24px;
    color: #555;
}

.legend h4 {
    text-align: center;
    font-size: 16px;
    margin: 2px 12px 8px;
    color: #777;
}

.legend span {
    position: relative;
    bottom: 3px;
}

.legend i {
    width: 18px;
    height: 18px;
    float: left;
    margin: 0 8px 0 0;
    opacity: 0.7;
}

.legend i.icon {
    background-size: 18px;
    background-color: rgba(255, 255, 255, 1);
}
</style>


