<template>
    <div>
        <div class="p-fluid">
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Salesman</label>
                    <Dropdown dataKey="salesman_id" v-model="filters.salesman_id" :loading="loadingDropdownSalesman" :options="dataDropdownSalesman" optionLabel="label" optionValue="salesman_id" placeholder="Pilih Salesman" :filter="true" :showClear="true" @filter="searchDropdownSalesman($event, 'filter')"/>
                </div>
            </div>
        </div>
        <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1" @click="getDataTable" />
    </div>
</template>

<script>

export default {
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownSalesman: false,

            // dataDropdown
            dataDropdownSalesman: null,

            // filter
            filters: {
                salesman_id: null,
            },
        }
    },
    mounted() {
        this.searchDropdownSalesman('');
    },
    watch: {
    },
    computed:{
    },
    methods: {
        // DROPDOWN
        searchDropdownSalesman(event, purpose, valueEdit){
            setTimeout(() => {

                if(valueEdit){
                    this.$refs.dlevel.filterValue = valueEdit;
                }

                if(purpose == "filter"){
                    this.loadingDropdownSalesman = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/salesman',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "filter"){
                        this.dataDropdownSalesman = res.data.data;
                        this.loadingDropdownSalesman = false;
                    }else if(purpose == null){
                        this.dataDropdownSalesman = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        getDataTable(){
            this.$emit('submit');
        }
    }
}
</script>

